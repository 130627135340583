import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Wohnungsfinanzierung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Wohnungsfinanzierung in 10 Schritten" showCalc={false} />
            <Article>
                <p>
                    Du hast dich dazu entschieden, eine Wohnung zu kaufen? Wunderbar! Jetzt stellt sich die Frage, wie
                    du die Wohnung finanzierst. Eine Wohnungsfinanzierung muss ganz unabhängig vom Budget gut überlegt
                    werden. Nimm dir vorher die nötige Zeit für einen Kreditvergleich.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Schritt 1: Haushaltsrechnung</h2>
                <p>
                    Mache eine genaue Haushaltsrechnung. Das ist der erste und wichtigste Schritt. Denn viele Menschen
                    unter- oder überschätzen die Geldsumme, die ihnen wirklich zur Verfügung steht. Wir haben deshalb zu
                    diesem Thema einen eigenen Artikel geschrieben, schau doch mal rein. Erst nach deiner
                    Haushaltsrechnung, kannst du richtig einschätzen, wieviel du dir leisten kannst und die Suche nach
                    deiner Traumimmobilie beginnen.
                </p>
                <hr />

                <h2>Schritt 2: Immobilie finden</h2>
                <p>
                    Es gibt unterschiedliche Orte, an denen du dich nach verfügbaren Wohnungen informieren kannst. Der
                    einfachste Weg ist, dich durch Online-Portale durch zu klicken. Auch hat fast jede Zeitung einen
                    Immobilienteil, den du dir ab jetzt täglich ansehen kannst. Informiere dich bei unterschiedlichen
                    Immobilienmakler*innen, aber vergiss nicht, dass es auch viele Menschen gibt, die eine Immobilie
                    besitzen, welche sie loswerden möchten, worum sie sich aber noch nicht gekümmert haben. Frage in
                    deinem Freundes- und Bekanntenkreis nach, vielleicht kannst du dir so die Maklergebühren sparen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Schritt 3: Sieh dir die Gegend an</h2>
                <p>
                    Wenn du eine Immobilie gefunden hast, ist der nächste Schritt vor oder im Zuge der Besichtigung
                    schon einmal die Gegend auszuchecken. Wenn du die Gegend noch nicht gut kennst, solltest du mehrere
                    Male dort spazieren und dir dabei überlegen, ob alles, was du brauchst, in deiner Nähe ist. Der Wert
                    einer Immobilie hängt nicht zufällig auch von ihrer Lage ab, denn sie beeinflusst das Leben an
                    diesem Ort beträchtlich.
                </p>
                <hr />

                <h2>Schritt 4: Wohnungsbesichtigung</h2>
                <p>
                    Der aufregendste Teil ist wohl die Wohnungsbesichtigung. Stell dir ehrlich die Fragen: Kannst du dir
                    vorstellen darin zu leben? Fühlst du dich wohl? Man kann viele Dinge in einer Eigentumswohnung so
                    gestalten, wie man möchte. Aber lasse dich nicht komplett von deinen Traumvorstellungen und Ideen
                    leiten, sondern inspiziere die Wohnung genau. Denn bestimmte Dinge sind daran nicht zu ändern. Es
                    ist unbedingt empfehlenswert, dir mehrere Wohnungen anzusehen. Was man bei der Wohnungsbesichtigung
                    beachten sollte und wo man genau nachfragen sollte, klären wir auch in einem eigenen Artikel.
                </p>
                <p>
                    Bevor du die Wohnung endgültig aussuchst, solltest du später eine zweite finale Besichtigung machen.
                    Nimm am besten jemanden mit, der mit dir genau alles überprüft, denn vier Augen sehen mehr als zwei.
                </p>
                <hr />

                <h2>Schritt 5: Einschätzung möglicher Renovierungskosten</h2>
                <p>
                    Bei der Besichtigung kannst du den Zustand der Immobilie genau prüfen und danach eine realistische
                    Einschätzung aller notwendigen Renovierungskosten machen. Wenn du mehrere Wohnungen besichtigt hast,
                    kannst du dir überlegen, ob eine Wohnung, in der sehr viel mehr gemacht werden müsste, als in einer
                    anderen, die vielleicht teurer ist, sich im Endeffekt wirklich als günstig herausstellt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Schritt 6: Informiere dich über Finanzierungsmöglichkeiten</h2>
                <p>
                    Hole dir eine Finanzierungsberatung ein. Wo und wie findest du den besten{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>
                    ? Welche Kreditarten eignen sich in deinem Fall? Über solche Dinge solltest du dich im vorhinein gut
                    informieren. Manchmal ist es deshalb sinnvoll, mit einem Experten oder einer Expertin darüber zu
                    sprechen.
                </p>
                <hr />

                <h2>Schritt 7: Kreditvergleich und -verhandlung</h2>
                <p>
                    Es ist sehr wichtig, mehrere Kredite miteinander zu vergleichen. Je nach deiner finanziellen
                    Situation und deinen Zukunftsplänen, muss entschieden werden, welcher Kredit am besten zu dir passt.
                    Welches Zinsmodell du wählst, kann sich später stark auf deine finanzielle Situation auswirken. Es
                    ist zwar möglich, sich umschulden zu lassen, doch sollte trotzdem kein Kredit leichtfertig
                    eingegangen werden. Nimm also niemals den ersten, den du findest, sondern Vergleiche verschiedene
                    Optionen.
                </p>
                <p>
                    <strong>Tipp:</strong> Bankberater*innen informieren dich auch über mögliche Förderungen. Weitere
                    wichtige Anlaufstellen sind hier die Abteilungen für Wohnbauförderung der einzelnen Bundesländer.
                </p>
                <p>
                    Während der Kreditverhandlungen kannst du immer versuchen, die für dich besten Konditionen
                    rauszuschlagen. Das solltest du dich in jedem Fall trauen. Aber auch wie das gemacht wird, muss
                    vorher gelernt sein.
                </p>
                <hr />

                <h2>Schritt 8: Notartermin vereinbaren</h2>
                <p>
                    Sobald du und der Verkäufer, die Verkäuferin der Immobilie euch einig werdet, kannst du einen
                    Notartermin vereinbaren.
                </p>
                <hr />

                <h2>Schritt 9: Finanzierungsbestätigung einholen</h2>
                <p>
                    Jetzt ist es an der Zeit, dir endgültig die FInanzierungsbestätigung von deiner Bank zu holen. Erst
                    danach kannst du dich auf den Kaufvertrag stürzen.
                </p>
                <hr />

                <h2>Schritt 10: Unterschrift des Kaufvertrags</h2>
                <p>
                    Bis zu diesem letzten Schritt wurde der Darlehensvertrag abgeschlossen und die Bank hat ins
                    Grundbuch eine Grundschuld eingetragen. Danach folgt die Kaufpreisüberweisung und die
                    Kreditauszahlung. Zum vereinbarten Zahlungszeitpunkt wird der{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    ausgezahlt und gemeinsam mit dem Eigenkapital an den Verkäufer oder auf ein Treuhänderkonto, das vom
                    Notar verwaltet wird, überwiesen. Außerdem werden nun die Maklerkosten, die Notarkosten und die
                    Grunderwerbssteuer fällig.
                </p>
                <p>
                    Bevor du den Kaufvertrag unterschreibst, gehst du gemeinsam mit deinem Notar den Kaufvertrag einmal
                    Schritt für Schritt durch. Erst danach wird der Vertrag endlich unterschrieben. Ab jetzt ist die
                    Wohnung dein und du beginnst mit der monatlichen Ratenzahlung!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wohnungsfinanzierung"}></BreadcrumbList>
            <ArticleStructuredData page={"wohnungsfinanzierung"} heading={"Wohnungsfinanzierung in 10 Schritten"} />
        </Layout>
    );
};

export default Wohnungsfinanzierung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.wohnungsfinanzierung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
